.container {
  margin-top: 25px;
  background: rgb(241, 242, 245);
}

.logo {
  height: 150px;
}

.subtitle {
  font-style: italic;
  font-size: x-large;
  color: rgb(174, 174, 235);
}

.featuredCourse {
  border-radius: 2px;
  background-color: rgb(174, 174, 235);
}