.searchprice {
    font-size: larger;
    color: cornflowerblue;
    text-decoration:none;
}

.card a:hover {
    text-decoration: none;
}

.carousel {
    height: 350px;
    width:500px;
    align-content: center;
  }
  
  .carousel-item,
  .carousel-inner,
  .carousel-inner img {
    height: 350px;
    width: 500px;
  }
  
  .carousel-item {
    text-align: center;

}



  .carousel-inner img {
    margin: auto;
  }
  
  #portfolio {
    background: #356;
  }

  .center {
    margin: auto;
    width: 60%;
    padding: 10px;}

    .capcolor{
        color: darkblue;
    }